<template>
  <div class="container-box">
    <div class="box-left">
      <div class="guide-box">
        <span class="numBox">
          <span class="num1">1</span>
          <span class="num2">/</span>
          <span class="num2">2</span>
        </span>
        <span class="txt">你想考的专业是？</span>
      </div>
      <div class="search-control">
        <search-control placeholder="请输入院校或专业代码、名称" :value.sync="keyword" @search-click="onSearchClick"></search-control>
      </div>
      <div class="dark-border school-filter">
        <ai-selection-filter :filter1.sync="filter1" :filter2.sync="filter2" :filter3.sync="filter3" :filter4.sync="filter4" :filter5.sync="filter5" :filter6.sync="filter6" :filter7.sync="filter7" :filter8.sync="filter8" :filter9.sync="filter9" :filter10.sync="filter10" :filter11.sync="filter11" :filter12.sync="filter12" :filter13.sync="filter13" @filter="onFilterClick"></ai-selection-filter>
      </div>
      <div class="guide-box">
        <span class="numBox">
          <span class="num1">2</span>
          <span class="num2">/</span>
          <span class="num2">2</span>
        </span>
        <span class="txt">以下是为你推荐的院校</span>
      </div>
      <div class="tabs-box">
        <el-tabs class="school-tabs" type="card" v-model="active" @tab-click="onTabChange">
          <el-tab-pane :label="`全部（${countAll}）`"></el-tab-pane>
          <el-tab-pane :label="`可冲击（${count985}）`"></el-tab-pane>
          <el-tab-pane :label="`较稳妥（${count211}）`"></el-tab-pane>
          <el-tab-pane :label="`可保底（${countHigher}）`"></el-tab-pane>
          <el-tab-pane :label="`对比（${countCompare}）`"></el-tab-pane>
        </el-tabs>
        <el-button v-if="isMembership" type="primary" size="small" class="report-button" @click="onShowStudent">生成择校报告</el-button>
        <div class="school-list" v-loading="loading" element-loading-text="加载中...">
          <template v-if="active != 4">
            <div v-for="(item, index) in majorInfos" :key="index">
              <ai-selection-item :unlocked="isMembership" :school="item.UniversityName" :college="item.CollegeName" :major="item.MajorName" :code="item.MajorCode" :province="item.UniversityProvince" :partition="item.UniversityPartition" :year="item.MajorEnrollmentYear" :learnway="item.MajorLearningWay" :admitInfoList="item.AdmitInfos" :compareVisible="compareVisible(item)" @item-click="onItemClick(item)" @unlock-click="onUnlockClick(item)" @compare-click="onAddCompareClick(item)"></ai-selection-item>
              <div v-if="index < majorInfos.length - 1" class="bottom-line"></div>
            </div>
          </template>
          <template v-else>
            <div>
              <el-button class="compare-button" type="primary" size="mini" plain @click.stop="onClearCompareClick">清空对比</el-button>
              <el-table :data="tableData" border>
                <el-table-column fixed align="center" prop="column" label="院校" width="110">
                </el-table-column>
                <el-table-column v-for="(column, index) in tableColumns" :key="index" :prop="column.prop" :label="column.label" width="200">
                  <template slot="header" slot-scope="scope">
                    <div>
                      <span>{{ column.label }}</span>
                      <i class="el-icon-close" title="移除" @click="onDelCompareClick(index)"></i>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </template>
        </div>
      </div>
      <div v-if="active != 4" class="pagination-box">
        <el-pagination background hide-on-single-page :current-page.sync="pageIndex" :page-size.sync="pageSize" :total.sync="totalCount" layout="total, prev, pager, next, jumper" @current-change="onCurrentChange"></el-pagination>
      </div>
    </div>
    <div class="box-right">
      <school-hot></school-hot>
    </div>

    <el-dialog center destroy-on-close :close-on-click-modal="true" :close-on-press-escape="true" :show-close="true" :visible.sync="studentDialog" title="填写择校报告信息" width="600px">
      <student-dialog1 @submit="onSubmitReport" @cancel="onCancelReport"></student-dialog1>
    </el-dialog>
  </div>
</template>

<script>
import AiSelectionFilter from "@/components/PC/AiSelectionFilter";
import AiSelectionItem from "@/components/PC/AiSelectionItem";
import SchoolHot from "@/components/PC/SchoolHot";
import SearchControl from "@/components/PC/SearchControl";
import StudentDialog1 from "@/components/PC/StudentDialog1";
import Config from "@/api/service";
import Tools from "@/tool";
import Manager from "@/manager";

export default {
  name: "PCModule",
  components: {
    AiSelectionFilter,
    AiSelectionItem,
    SchoolHot,
    SearchControl,
    StudentDialog1,
  },
  data() {
    return {
      loading: true,
      studentDialog: false,
      isMembership: false,
      active: 0,
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0,
      countAll: 0,
      count985: 0,
      count211: 0,
      countHigher: 0,
      keyword: '',
      filter1: [],
      filter2: [],
      filter3: '',
      filter4: [],
      filter5: [],
      filter6: [],
      filter7: [],
      filter8: '',
      filter9: '',
      filter10: '',
      filter11: '',
      filter12: '',
      filter13: '',
      majorInfos: [],
      admitCompares: [],
    };
  },
  computed: {
    compareVisible() {
      return function (item) {
        return this.admitCompares.filter(p => p.UniversityCode == item.UniversityCode && p.CollegeCode == item.CollegeCode && p.MajorCode == item.MajorCode && p.MajorLearningWay == item.MajorLearningWay).length == 0;
      }
    },
    countCompare() {
      return this.admitCompares.length;
    },
    tableColumns() {
      var columns = [];
      for (var i = 0; i < this.admitCompares.length; i++) {
        columns.push({
          prop: 'column' + i,
          label: this.admitCompares[i].UniversityName,
        });
      }
      return columns;
    },
    tableData() {
      var data = [
        { column: "学院" },
        { column: "专业" },
        { column: "研究方向" },
        { column: "初试科目" },
        { column: "复试科目" },
        { column: "拟招生人数" },
        { column: "录取年份" },
        { column: "录取总人数" },
        { column: "一志愿录取" },
        { column: "一志愿最低分" },
        { column: "一志愿最高分" },
        { column: "目标分数" },
        { column: "调剂人数" },
        { column: "调剂最低分" },
        { column: "调剂最高分" },
      ];
      for (var i = 0; i < this.admitCompares.length; i++) {
        data[0]['column' + i] = this.admitCompares[i].CollegeName;
        data[1]['column' + i] = this.admitCompares[i].MajorCode + this.admitCompares[i].MajorName;
        data[2]['column' + i] = this.admitCompares[i].MajorResearchDirection;
        data[3]['column' + i] = this.admitCompares[i].MajorFirstSubject;
        data[4]['column' + i] = this.admitCompares[i].MajorSecondSubject;
        data[5]['column' + i] = this.admitCompares[i].MajorPlannedEnrollment;
        data[6]['column' + i] = this.admitCompares[i].AdmitYear;
        data[7]['column' + i] = this.admitCompares[i].AdmitTotalNumber;
        data[8]['column' + i] = this.admitCompares[i].AdmitVolunteerNumber;
        data[9]['column' + i] = this.admitCompares[i].AdmitVolunteerMinScore;
        data[10]['column' + i] = this.admitCompares[i].AdmitVolunteerMaxScore;
        data[11]['column' + i] = this.admitCompares[i].AdmitTargetScore;
        data[12]['column' + i] = this.admitCompares[i].AdmitAdjustedNumber;
        data[13]['column' + i] = this.admitCompares[i].AdmitAdjustMinScore;
        data[14]['column' + i] = this.admitCompares[i].AdmitAdjustMaxScore;
      }
      return data;
    },
  },
  methods: {
    //获取路由参数
    getRoutParams() {
      this.keyword = this.$route.query.keyword;
    },
    //获取用户是否是会员
    getIsMembership() {
      if (!this.$store.getters.isLogin) {
        return
      }
      var model = {};
      this.$axios
        .post(Config.member.purchased, model)
        .then((res) => {
          this.isMembership = res.data.Data.IsPurchased;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //获取专业数量统计
    getMajorCount() {
      var model = {
        SearchKeyword: this.keyword,
        MajorLearningWays: this.filter1,
        MajorDegreeTypes: this.filter2,
        MajorBelongcategory: this.filter3,
        MajorBelongFirstDisciplines: this.filter4,
        MajorCodes: this.filter5.map(p => p.substring(0, 6)),
        UniversityProvinces: this.filter6,
        UniversityTeachTypes: this.filter7,
        UniversityCharacteristic: this.filter8,
        MajorSpecialRecruitmentPlan: this.filter9,
        MajorFirstSubject1: this.filter10,
        MajorFirstSubject2: this.filter11,
        MajorFirstSubject3: this.filter12,
        MajorResearchDirection: this.filter13,
      };
      this.$axios
        .post(Config.aiSelection.majorCount, model)
        .then((res) => {
          let data = res.data.Data;
          this.countAll = data.CountAll;
          this.count985 = data.Count985;
          this.count211 = data.Count211;
          this.countHigher = data.CountHigher;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //获取报录数据列表
    getMajorList() {
      this.loading = true;
      var model = {
        SearchType: this.active,
        SearchKeyword: this.keyword,
        MajorLearningWays: this.filter1,
        MajorDegreeTypes: this.filter2,
        MajorBelongcategory: this.filter3,
        MajorBelongFirstDisciplines: this.filter4,
        MajorCodes: this.filter5.map(p => p.substring(0, 6)),
        UniversityProvinces: this.filter6,
        UniversityTeachTypes: this.filter7,
        UniversityCharacteristic: this.filter8,
        MajorSpecialRecruitmentPlan: this.filter9,
        MajorFirstSubject1: this.filter10,
        MajorFirstSubject2: this.filter11,
        MajorFirstSubject3: this.filter12,
        MajorResearchDirection: this.filter13,
        PageIndex: this.pageIndex,
        PageSize: this.pageSize,
      };
      this.$axios
        .post(Config.aiSelection.majorList, model)
        .then((res) => {
          let data = res.data.Data;
          this.totalCount = data.TotalCount;
          this.majorInfos = data.RecommendMajorInfos;
          this.loading = false;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //获取报录数据对比列表
    getAdmitCompareInfos() {
      if (!this.$store.getters.isLogin) {
        return
      }
      var model = {};
      this.$axios
        .post(Config.aiSelection.compare.list, model)
        .then((res) => {
          this.admitCompares = res.data.Data.AdmitCompareInfos;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //添加报录数据对比记录
    addAdmitCompareInfo(item) {
      var model = {
        UniversityCode: item.UniversityCode,
        CollegeCode: item.CollegeCode,
        MajorCode: item.MajorCode,
        MajorLearningWay: item.MajorLearningWay,
        ProxyCode: this.$store.state.proxyCode,
      };
      return this.$axios
        .post(Config.aiSelection.compare.add, model)
        .then((res) => {

        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //删除报录数据对比记录
    delAdmitCompareInfo(compareGuid) {
      var model = {
        CompareGuid: compareGuid,
      };
      return this.$axios
        .post(Config.aiSelection.compare.delete, model)
        .then((res) => {

        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //清空报录数据对比记录
    clearAdmitCompareInfo() {
      return this.$axios
        .post(Config.aiSelection.compare.clear, {})
        .then((res) => {

        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //添加访问商品记录
    addVisitProductRecord() {
      Manager.addVisitProductRecord("AI择校");
    },
    //点击大学专业项
    onItemClick(item) {
      this.$router.push({
        name: 'Admit1',
        params: {
          proxyCode: this.$store.state.proxyCode,
          universityCode: item.UniversityCode,
          collegeCode: item.CollegeCode,
          majorCode: item.MajorCode,
          majorLearnway: item.MajorLearningWay
        }
      })
    },
    //点击解锁
    onUnlockClick(item) {
      this.$router.push({
        name: "SubmitMemberOrder",
        params: {
          proxyCode: this.$store.state.proxyCode
        }
      });
    },
    //点击加入对比
    async onAddCompareClick(item) {
      this.loading = true;
      await this.addAdmitCompareInfo(item);
      await this.getAdmitCompareInfos();
      this.loading = false;
    },
    //移除对比
    async onDelCompareClick(index) {
      this.loading = true;
      await this.delAdmitCompareInfo(this.admitCompares[index].CompareGuid);
      await this.getAdmitCompareInfos();
      this.loading = false;
    },
    //点击清空对比
    async onClearCompareClick() {
      this.loading = true;
      await this.clearAdmitCompareInfo();
      await this.getAdmitCompareInfos();
      this.loading = false;
    },
    //点击搜索
    onSearchClick() {
      this.pageIndex = 1;
      this.filter1 = [];
      this.filter2 = [];
      this.filter3 = '';
      this.filter4 = [];
      this.filter5 = [];
      this.filter6 = [];
      this.filter7 = [];
      this.filter8 = '';
      this.filter9 = '';
      this.filter10 = '';
      this.filter11 = '';
      this.filter12 = '';
      this.filter13 = '';
      this.getMajorCount();
      this.getMajorList();
    },
    //点击过滤
    onFilterClick() {
      this.pageIndex = 1;
      this.keyword = '';
      this.getMajorCount();
      this.getMajorList();
    },
    //选项卡切换
    onTabChange() {
      if (this.active < 4) {
        this.pageIndex = 1;
        this.getMajorList();
      }
    },
    //点击分页
    onCurrentChange() {
      if (this.active < 4) {
        this.getMajorList();
      }
    },
    //弹窗填写学生报考信息
    onShowStudent() {
      if (Tools.isEmpty(this.filter3)) {
        this.$alert('请选择所属门类。', '提示');
      } else if (this.countAll == 0) {
        this.$alert('筛选结果无数据导出。', '提示');
      } else if (this.countAll + this.countCompare > 1000) {
        this.$alert('择校报告仅支持小于1000条数据的报告生成，请优化减少院校总数量。', '提示');
      } else {
        this.studentDialog = true;
      }
    },
    //生成择校报告
    onSubmitReport(formData) {
      if (!this.$store.getters.isLogin) {
        this.$loginDialog.show(true);
      } else {
        //添加报告生成任务
        this.studentDialog = false;
        var model = {
          StudentName: formData.studentName,
          StudentPhone: formData.studentPhone,
          StudentSchool: formData.studentSchool,
          StudentMajor: formData.studentMajor,
          StudentGraded: formData.studentGraded,
          StudentScore: formData.studentScore,
          FilterMajorLearningWays: this.filter1,
          FilterMajorDegreeTypes: this.filter2,
          FilterMajorBelongcategory: this.filter3,
          FilterMajorBelongFirstDisciplines: this.filter4,
          FilterMajorCodes: this.filter5.map(p => p.substring(0, 6)),
          FilterUniversityProvinces: this.filter6,
          FilterUniversityTeachTypes: this.filter7,
          FilterUniversityCharacteristic: this.filter8,
          FilterMajorSpecialRecruitmentPlan: this.filter9,
          FilterMajorFirstSubject1: this.filter10,
          FilterMajorFirstSubject2: this.filter11,
          FilterMajorFirstSubject3: this.filter12,
          FilterMajorResearchDirection: this.filter13,
          ProxyCode: this.$store.state.proxyCode,
        };
        this.$axios
          .post(Config.aiSelection.reportGenerate, model)
          .then((res) => {
            var result = res.data.Data;
            if (result.ReportState == -1) {
              //跳转订单提交
              this.$router.push({
                name: "SubmitMemberOrder",
                params: {
                  proxyCode: this.$store.state.proxyCode
                }
              });
              // this.$router.push({
              //   name: "OrderSubmit3",
              //   params: {
              //     proxyCode: this.$store.state.proxyCode
              //   },
              //   query: {
              //     universityCode: this.analysisResult.UniversityCode,
              //     universityName: this.analysisResult.UniversityName,
              //     collegeCode: this.analysisResult.CollegeCode,
              //     collegeName: this.analysisResult.CollegeName,
              //     majorCode: this.analysisResult.MajorCode,
              //     majorName: this.analysisResult.MajorName,
              //     politicalScore: this.analysisResult.PoliticalScore,
              //     englishScore: this.analysisResult.EnglishScore,
              //     majorScore1: this.analysisResult.MajorScore1,
              //     majorScore2: this.analysisResult.MajorScore2,
              //     studentName: this.analysisResult.StudentName,
              //     studentPhone: this.analysisResult.StudentPhone,
              //   }
              // });
            } else {
              if (this.countAll > 600) {
                this.$alert('调剂报告仅支持小于1000条数据的报告生成，请优化减少调剂院校总数量。', '提示');
              } else {
                if (result.ReportState == 0) {
                  this.$alert('报告正在生成中，请到我的资料里面查看生成进度。', '提示');
                } else {
                  this.$alert('报告已生成完毕，请到我的资料里面下载。', '提示');
                }
              }
            }
          })
          .catch((error) => {
            this.$message.error(error.message);
          });
      }
    },
    //取消生成报告
    onCancelReport() {
      this.studentDialog = false;
    },
  },
  mounted() {
    this.getRoutParams();
    this.getIsMembership();
    this.getMajorCount();
    this.getMajorList();
    this.getAdmitCompareInfos();
    this.addVisitProductRecord();
  }
};
</script>

<style scoped lang="less">
.container-box {
  display: flex;
  flex-direction: row;
}

.box-left {
  flex: 1;
  overflow: hidden;
}

.box-right {
  width: 300px;
  margin: 0 0 0 50px;
}

.tabs-box {
  position: relative;
}

.report-button {
  position: absolute;
  right: 0px;
  top: 0px;
}

.search-control {
  width: 400px;
  margin: 10px 0;
}

.school-filter {
  padding: 20px;
}

.school-tabs {
  margin-top: 10px;
}

.school-list {
  min-height: 100px;
  padding: 20px;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
}

.dark-border {
  border: 1px solid #e6e6e6;
}

.bottom-line {
  border-top: 1px solid #e6e6e6;
  margin: 10px 0;
}

.pagination-box {
  text-align: center;
  margin: 10px;
}

.guide-box {
  margin: 20px 0;
  font-size: 18px;

  .numBox {
    display: inline;
    letter-spacing: 5px;

    .num1 {
      color: #ff6602;
      font-size: 20px;
      font-weight: 700;
    }

    .num2 {
      color: gray;
      font-size: 16px;
    }
  }

  .txt {
    margin-left: 10px;
  }
}

.compare-button {
  float: right;
  margin-bottom: 10px;
}

.el-icon-close {
  cursor: pointer;
  margin-left: 10px;
}

.el-icon-close:hover {
  color: #ff6602;
}

::v-deep .el-tabs__header {
  margin: 0px;
}

::v-deep .el-tabs--card>.el-tabs__header .el-tabs__nav {
  border-radius: 0px;
}

::v-deep .el-table td.el-table__cell div {
  white-space: pre-wrap;
}
</style>