<template>
    <div>
        <van-sticky>
            <title-bar2 title="AI择校" />
            <title-search :value.sync="keyword" placeholder="请输入院校或专业代码、名称" @search="onSearch" />
        </van-sticky>
        <div class="admit-divider"></div>
        <div class="guide-box">
            <span class="num-box">
                <span class="num1">1</span>
                <span class="num2">/</span>
                <span class="num2">2</span>
            </span>
            <span class="txt">你想考的专业是？</span>
        </div>
        <ai-selection-filter :filter1.sync="filter1" :filter2.sync="filter2" :filter3.sync="filter3" :filter4.sync="filter4" :filter5.sync="filter5" :filter6.sync="filter6" :filter7.sync="filter7" :filter8.sync="filter8" :filter9.sync="filter9" :filter10.sync="filter10" :filter11.sync="filter11" :filter12.sync="filter12" :filter13.sync="filter13" @filter="onFilter"></ai-selection-filter>
        <div class="admit-divider"></div>
        <div class="admit-between">
            <div class="guide-box">
                <span class="num-box">
                    <span class="num1">2</span>
                    <span class="num2">/</span>
                    <span class="num2">2</span>
                </span>
                <span class="txt">以下是为你推荐的院校</span>
            </div>
            <van-button v-if="isMembership" round size="mini" color="#FE5E03" class="report-button" @click="onShowStudent">生成择校报告</van-button>
        </div>
        <van-tabs v-model="active" @change="onChange">
            <!-- <van-tab :title="`全部 ${countAll}`"></van-tab> -->
            <van-tab :title="`冲击 ${count985}`"></van-tab>
            <van-tab :title="`稳妥 ${count211}`"></van-tab>
            <van-tab :title="`保底 ${countHigher}`"></van-tab>
            <van-tab :title="`对比 ${countCompare}`"></van-tab>
        </van-tabs>
        <template v-if="active != 3">
            <van-list class="admit-list" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                <ai-selection-item class="admit-item" v-for="(item, index) in majorInfos" :key="index" :unlocked="isMembership" :school="item.UniversityName" :college="item.CollegeName" :major="item.MajorName" :code="item.MajorCode" :province="item.UniversityProvince" :partition="item.UniversityPartition" :year="item.MajorEnrollmentYear" :learnway="item.MajorLearningWay" :admitInfoList="item.AdmitInfos" :img="schoolImg(item.UniversityCode)" :compareVisible="compareVisible(item)" @item-click="onItemClick(item)" @unlock-click="onUnlockClick(item)" @compare-click="onAddCompareClick(item)"></ai-selection-item>
            </van-list>
        </template>
        <template v-else>
            <div class="compare-box">
                <van-button class="compare-button" plain hairline color="#FE5E03" size="mini" @click="onClearCompareClick">清空对比</van-button>
                <el-table :data="tableData" border>
                    <el-table-column fixed align="center" prop="column" label="院校" width="110">
                    </el-table-column>
                    <el-table-column v-for="(column, index) in tableColumns" :key="index" :prop="column.prop" :label="column.label" width="200">
                        <template slot="header" slot-scope="scope">
                            <div>
                                <span>{{ column.label }}</span>
                                <i class="el-icon-close" title="移除" @click="onDelCompareClick(index)"></i>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </template>

        <van-dialog v-model="studentDialog" :showConfirmButton="false" title="填写择校报告信息">
            <student-dialog1 @submit="onSubmitReport" @cancel="onCancelReport"></student-dialog1>
        </van-dialog>
    </div>
</template>

<script>
import TitleBar2 from "@/components/MT/TitleBar2";
import TitleSearch from "@/components/MT/TitleSearch";
import StudentDialog1 from "@/components/MT/StudentDialog1";
import AiSelectionFilter from "@/components/MT/AiSelectionFilter";
import AiSelectionItem from "@/components/MT/AiSelectionItem";
import Config from "@/api/service";
import Tools from "@/tool";
import Manager from "@/manager";

export default {
    name: "MTModule",
    components: {
        TitleBar2,
        TitleSearch,
        StudentDialog1,
        AiSelectionFilter,
        AiSelectionItem,
    },
    data() {
        return {
            studentDialog: false,
            keyword: null,
            active: 0,
            index: 0,
            total: 0,
            countAll: 0,
            count985: 0,
            count211: 0,
            countHigher: 0,
            loading: false,
            finished: false,
            filter1: [],
            filter2: [],
            filter3: '',
            filter4: [],
            filter5: [],
            filter6: [],
            filter7: [],
            filter8: '',
            filter9: '',
            filter10: '',
            filter11: '',
            filter12: '',
            filter13: '',
            isMembership: false,
            majorInfos: [],
            admitCompares: [],
        };
    },
    computed: {
        schoolImg() {
            return function (code) {
                if (code) {
                    return `${Config.school.logo}?SchoolCode=${code}`;
                }
            }
        },
        compareVisible() {
            return function (item) {
                return this.admitCompares.filter(p => p.UniversityCode == item.UniversityCode && p.CollegeCode == item.CollegeCode && p.MajorCode == item.MajorCode && p.MajorLearningWay == item.MajorLearningWay).length == 0;
            }
        },
        countCompare() {
            return this.admitCompares.length;
        },
        tableColumns() {
            var columns = [];
            for (var i = 0; i < this.admitCompares.length; i++) {
                columns.push({
                    prop: 'column' + i,
                    label: this.admitCompares[i].UniversityName,
                });
            }
            return columns;
        },
        tableData() {
            var data = [
                { column: "学院" },
                { column: "专业" },
                { column: "研究方向" },
                { column: "初试科目" },
                { column: "复试科目" },
                { column: "拟招生人数" },
                { column: "录取年份" },
                { column: "录取总人数" },
                { column: "一志愿录取" },
                { column: "一志愿最低分" },
                { column: "一志愿最高分" },
                { column: "目标分数" },
                { column: "调剂人数" },
                { column: "调剂最低分" },
                { column: "调剂最高分" },
            ];
            for (var i = 0; i < this.admitCompares.length; i++) {
                data[0]['column' + i] = this.admitCompares[i].CollegeName;
                data[1]['column' + i] = this.admitCompares[i].MajorCode + this.admitCompares[i].MajorName;
                data[2]['column' + i] = this.admitCompares[i].MajorResearchDirection;
                data[3]['column' + i] = this.admitCompares[i].MajorFirstSubject;
                data[4]['column' + i] = this.admitCompares[i].MajorSecondSubject;
                data[5]['column' + i] = this.admitCompares[i].MajorPlannedEnrollment;
                data[6]['column' + i] = this.admitCompares[i].AdmitYear;
                data[7]['column' + i] = this.admitCompares[i].AdmitTotalNumber;
                data[8]['column' + i] = this.admitCompares[i].AdmitVolunteerNumber;
                data[9]['column' + i] = this.admitCompares[i].AdmitVolunteerMinScore;
                data[10]['column' + i] = this.admitCompares[i].AdmitVolunteerMaxScore;
                data[11]['column' + i] = this.admitCompares[i].AdmitTargetScore;
                data[12]['column' + i] = this.admitCompares[i].AdmitAdjustedNumber;
                data[13]['column' + i] = this.admitCompares[i].AdmitAdjustMinScore;
                data[14]['column' + i] = this.admitCompares[i].AdmitAdjustMaxScore;
            }
            return data;
        },
    },
    methods: {
        //获取路由参数
        getRoutParams() {
            this.keyword = this.$route.query.keyword;
        },
        //获取用户是否是会员
        getIsMembership() {
            if (!this.$store.getters.isLogin) {
                return
            }
            var model = {};
            this.$axios
                .post(Config.member.purchased, model)
                .then((res) => {
                    this.isMembership = res.data.Data.IsPurchased;
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        //获取专业数量统计
        getMajorCount() {
            var model = {
                SearchKeyword: this.keyword,
                MajorLearningWays: this.filter1,
                MajorDegreeTypes: this.filter2,
                MajorBelongcategory: this.filter3,
                MajorBelongFirstDisciplines: this.filter4,
                MajorCodes: this.filter5.map(p => p.substring(0, 6)),
                UniversityProvinces: this.filter6,
                UniversityTeachTypes: this.filter7,
                UniversityCharacteristic: this.filter8,
                MajorSpecialRecruitmentPlan: this.filter9,
                MajorFirstSubject1: this.filter10,
                MajorFirstSubject2: this.filter11,
                MajorFirstSubject3: this.filter12,
                MajorResearchDirection: this.filter13,
            };
            return this.$axios
                .post(Config.aiSelection.majorCount, model)
                .then((res) => {
                    let data = res.data.Data;
                    this.countAll = data.CountAll;
                    this.count985 = data.Count985;
                    this.count211 = data.Count211;
                    this.countHigher = data.CountHigher;
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        //获取报录列表
        getMajorList() {
            var model = {
                SearchType: this.active + 1,
                SearchKeyword: this.keyword,
                MajorLearningWays: this.filter1,
                MajorDegreeTypes: this.filter2,
                MajorBelongcategory: this.filter3,
                MajorBelongFirstDisciplines: this.filter4,
                MajorCodes: this.filter5.map(p => p.substring(0, 6)),
                UniversityProvinces: this.filter6,
                UniversityTeachTypes: this.filter7,
                UniversityCharacteristic: this.filter8,
                MajorSpecialRecruitmentPlan: this.filter9,
                MajorFirstSubject1: this.filter10,
                MajorFirstSubject2: this.filter11,
                MajorFirstSubject3: this.filter12,
                MajorResearchDirection: this.filter13,
                PageIndex: ++this.index,
                PageSize: 10,
            };
            return this.$axios
                .post(Config.aiSelection.majorList, model)
                .then((res) => {
                    let data = res.data.Data;
                    this.loading = false;
                    this.total = data.TotalCount;
                    this.majorInfos.push(...data.RecommendMajorInfos);
                    if (this.index >= data.PageCount) {
                        this.finished = true;
                    }
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        //获取报录数据对比列表
        getAdmitCompareInfos() {
            if (!this.$store.getters.isLogin) {
                return
            }
            var model = {};
            return this.$axios
                .post(Config.aiSelection.compare.list, model)
                .then((res) => {
                    this.admitCompares = res.data.Data.AdmitCompareInfos;
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        //添加报录数据对比记录
        addAdmitCompareInfo(item) {
            var model = {
                UniversityCode: item.UniversityCode,
                CollegeCode: item.CollegeCode,
                MajorCode: item.MajorCode,
                MajorLearningWay: item.MajorLearningWay,
                ProxyCode: this.$store.state.proxyCode,
            };
            return this.$axios
                .post(Config.aiSelection.compare.add, model)
                .then((res) => {

                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        //删除报录数据对比记录
        delAdmitCompareInfo(compareGuid) {
            var model = {
                CompareGuid: compareGuid,
            };
            return this.$axios
                .post(Config.aiSelection.compare.delete, model)
                .then((res) => {

                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        //清空报录数据对比记录
        clearAdmitCompareInfo() {
            return this.$axios
                .post(Config.aiSelection.compare.clear, {})
                .then((res) => {

                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        //添加访问商品记录
        addVisitProductRecord() {
            Manager.addVisitProductRecord("AI择校");
        },
        //点击搜索
        onSearch() {
            this.filter1 = [];
            this.filter2 = [];
            this.filter3 = '';
            this.filter4 = [];
            this.filter5 = [];
            this.filter6 = [];
            this.filter7 = [];
            this.filter8 = '';
            this.filter9 = '';
            this.filter10 = '';
            this.filter11 = '';
            this.filter12 = '';
            this.filter13 = '';
            this.onReload();
        },
        //点击过滤
        onFilter() {
            this.keyword = '';
            this.onReload();
        },
        //重新加载列表
        onReload() {
            this.finished = false;
            this.loading = true;
            this.index = 0;
            this.majorInfos = [];
            this.getIsMembership();
            this.getMajorCount();
            this.getMajorList();
            this.getAdmitCompareInfos();
        },
        //加载当前列表
        onLoad() {
            this.getMajorList();
        },
        //选项卡切换
        onChange() {
            if (this.active < 3) {
                this.onReload();
            }
        },
        //弹窗填写学生报考信息
        onShowStudent() {
            if (Tools.isEmpty(this.filter3)) {
                this.$dialog({ title: '提示', message: '请选择所属门类。', });
            } else if (this.countAll == 0) {
                this.$dialog({ title: '提示', message: '筛选结果无数据导出。', });
            } else if (this.countAll + this.countCompare > 1000) {
                this.$dialog({ title: '提示', message: '择校报告仅支持小于1000条数据的报告生成，请优化减少院校总数量。', });
            } else {
                this.studentDialog = true;
            }
        },
        //生成择校报告
        onSubmitReport(formData) {
            this.studentDialog = false;
            //添加报告生成任务
            var model = {
                StudentName: formData.studentName,
                StudentPhone: formData.studentPhone,
                StudentSchool: formData.studentSchool,
                StudentMajor: formData.studentMajor,
                StudentGraded: formData.studentGraded,
                StudentScore: formData.studentScore,
                FilterMajorLearningWays: this.filter1,
                FilterMajorDegreeTypes: this.filter2,
                FilterMajorBelongcategory: this.filter3,
                FilterMajorBelongFirstDisciplines: this.filter4,
                FilterMajorCodes: this.filter5.map(p => p.substring(0, 6)),
                FilterUniversityProvinces: this.filter6,
                FilterUniversityTeachTypes: this.filter7,
                FilterUniversityCharacteristic: this.filter8,
                FilterMajorSpecialRecruitmentPlan: this.filter9,
                FilterMajorFirstSubject1: this.filter10,
                FilterMajorFirstSubject2: this.filter11,
                FilterMajorFirstSubject3: this.filter12,
                FilterMajorResearchDirection: this.filter13,
                ProxyCode: this.$store.state.proxyCode,
            };
            this.$axios
                .post(Config.aiSelection.reportGenerate, model)
                .then((res) => {
                    var result = res.data.Data;
                    if (result.ReportState == 0) {
                        this.$dialog({ title: '提示', message: '报告正在生成中，请到我的资料里面查看生成进度。', });
                    } else if (result.ReportState == 1) {
                        this.$dialog({ title: '提示', message: '报告已生成完毕，请到我的资料里面下载。', });
                    } else {
                        this.$dialog({ title: '提示', message: '报告未知状态。', });
                    }
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        //取消生成报告
        onCancelReport() {
            this.studentDialog = false;
        },
        //点击报录项
        onItemClick(item) {
            this.$router.push({
                name: "Admit1",
                params: {
                    proxyCode: this.$store.state.proxyCode,
                    universityCode: item.UniversityCode,
                    collegeCode: item.CollegeCode,
                    majorCode: item.MajorCode,
                    majorLearnway: item.MajorLearningWay
                }
            });
        },
        //点击解锁
        onUnlockClick(item) {
            this.$router.push({
                name: "SubmitMemberOrder",
                params: {
                    proxyCode: this.$store.state.proxyCode
                }
            });
        },
        //点击加入对比
        async onAddCompareClick(item) {
            await this.addAdmitCompareInfo(item);
            await this.getAdmitCompareInfos();
            this.$toast.success("加入对比成功。");
        },
        //移除对比
        async onDelCompareClick(index) {
            await this.delAdmitCompareInfo(this.admitCompares[index].CompareGuid);
            await this.getAdmitCompareInfos();
            this.$toast.success("移除对比成功。");
        },
        //清空对比
        async onClearCompareClick() {
            await this.clearAdmitCompareInfo();
            await this.getAdmitCompareInfos();
            this.$toast.success("清空对比成功。");
        },
    },
    mounted() {
        this.getRoutParams();
        this.getIsMembership();
        this.getMajorCount();
        this.getAdmitCompareInfos();
        this.addVisitProductRecord();
    }
};
</script>
<style scoped lang="less">
.admit-list {
    margin-top: 10px;
}

.admit-item {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.admit-divider {
    margin: 20px 0;
}

.admit-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.guide-box {
    background-color: #F6F6F6;
    padding-left: 10px;
    border-width: 0px;
    font-size: 14px;
    font-weight: 600;

    .num-box {
        display: inline;
        letter-spacing: 5px;

        .num1 {
            color: #FF6602;
            font-size: 18px;
            font-weight: 700;
        }

        .num2 {
            color: gray;
            font-size: 14px;
        }
    }
}

.compare-box {
    margin: 5px;

    .compare-button {
        float: right;
        margin-bottom: 5px;
    }
}

.report-button {
    width: 90px;
    height: 24px;
    margin-right: 10px;
}

::v-deep .van-tabs__nav {
    background-color: transparent;
}

::v-deep .el-table__fixed {
    z-index: 0;
}

::v-deep .el-table td.el-table__cell div {
    white-space: pre-wrap;
}
</style>